<template>
  <v-container fluid>
    <v-row
        justify="start"
        align="start"
        width="100%"
        class="ml-3 pr-4"
    >
      <v-row>
        <v-col cols="12" sm="6" md="6" >
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </v-col>
        <v-col cols="12" offset-sm="1" offset-md="1" sm="1" md="1">
          <v-select
              class="pt-1 mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              dense
              solo
          ></v-select>
        </v-col>
      </v-row>
      <v-col cols="12" sm="12" md="12" class="pt-0 px-0">
        <v-data-table
            :headers="headers"
            :items="statistic"
            :items-per-page="100"
            :sort-by="['balance']"
            :sort-desc="[false]"
            :page.sync="page"
            :loading="loading"
            :server-items-length="total"
            @page-count="pageCount = $event"
            hide-default-footer
            dense
        >
          <!--      <template v-slot:item.id="{ item }">-->
          <!--        <a target="_blank" rel="noopener noreferrer"-->
          <!--           :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"-->
          <!--        >{{ item.id}}-->
          <!--        </a>-->
          <!--      </template>-->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import api from '@/utils/api'
  // import { IFACE_SPENDING_LAST_UPDATE } from '@/store/actions/iface'

  export default {
    name: 'AAAAccounts',
    data () {
      return {
        itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
        headers: [
          {text: 'uid', value: 'uid', sortable: false,},
          {text: 'OS ID', value: 'os_id', sortable: false,},
          {text: 'Page ID', value: 'page_id', sortable: false,},
          {text: 'Bid floor, min', value: 'min_bidfloor'},
          {text: 'Bid floor, max', value: 'max_bidfloor'},
          {text: 'Price', value: 'price', sortable: false,}
        ],
        statistic: [],
        total: 0,
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        loading: false
      }
    },

    async mounted () {
      this.getDataFromApi()
    },

    methods: {
      async getDataFromApi () {
        this.loading = true

        try {
          const res = await api.dspStatistic(this.itemsPerPage, this.page - 1)
          this.$set(this, 'total', res.data.total)
          this.$set(this, 'statistic', res.data.statistic)
          // console.info('getDataFromApi', res)
        } catch (e) {
          console.error('getDataFromApi', e)
        }
        this.loading = false
      },
    },

    watch: {
      page () {
        this.getDataFromApi()
      },
    }
  }
</script>

<style scoped>

</style>